<template lang="pug">
  on-viewport(@on-viewport="onViewport" :rootMargin="rootMargin" :thresholds="thresholds")
    template(#default="{ invalidBrowser }")
      slot(:shown="invalidBrowser || loaded" v-if="invalidBrowser || loaded")
</template>

<script>
  export default {
    name: 'LazyComponent',

    components: {
      OnViewport: () => import('./Effects/OnViewport'),
    },

    props: {
      thresholds: {
        type: Array,
        default: () => [0.5, 0.5],
      },
      rootMargin: {
        type: String,
        default: () => '0px 0px 0px 0px',
      },
    },

    data: () => ({
      loaded: false,
    }),

    methods: {
      onViewport(scrollingSettings) {
        if (scrollingSettings.shown) {
          this.loaded = true;
          // Emit the scrolling settings
          this.$emit('loaded-settings', scrollingSettings);
        }
      },
    },
  };
</script>
